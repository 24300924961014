@import "./../../../design-tokens/css-variables.scss";

.outdoors-carousel {
    margin: 0;
    padding: 0;
}

.outdoors-title {
    font-size: var(--font-size-h1);
    color: var(--color-basic-secondary);
    font-weight: bold;
    font-family: 'Nunito';
    margin-bottom: 2.5vh;
}


@media screen and (min-width: 1200px) {

    .leisure-content-container {
        width: 75vw;
        margin-right: 8vw;
        margin-top: 2vh;
        font-size: var(--font-size-h0);
    }

    .music-content-container {
        display: flex;
        flex-direction: row;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
    }

    .music-content-container-reverse {
        display: flex;
        flex-direction: row;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
    }

    .content-container {
        display: flex;
        flex-direction: row;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
        animation-duration: 2s;
        animation-name: slide-right;
    }

    .outdoors-subtitle {
        padding-left: calc(5px + .5vw);
        padding-right: calc(5px + 3vw);
        font-size: var(--font-size-h4);
        text-align: left;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-style: italic;
        margin-bottom: 2.5vh;
    }

    .content-container-sl {
        display: flex;
        flex-direction: row;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
        animation-duration: 2s;
        animation-name: slide-left;
        align-items: center;
        justify-content: flex-start;
    }

    .content-container-header {
        display: flex;
        flex-direction: row;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h0);
        margin-bottom: 5vh;
    }

    .leisure-content {
        padding-left: calc(5px + 0.5vw);
        padding-right: calc(5px + 3vw);
        font-size: var(--font-size-h2);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        margin-bottom: 2.5vh;
    }

    .tennis-leisure-content {
        padding-left: calc(5px + 3vw);
        padding-right: calc(5px + 0.5vw);
        font-size: var(--font-size-h2);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
    }

    .viola-leisure-content {
        padding-left: calc(5px + 0.5vw);
        padding-right: calc(5px + 3vw);
        font-size: var(--font-size-h2);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
    }

    .leisure-content-h3 {
        //padding-left: calc(5px + 0.5vw);
        padding-right: calc(5px + 3vw);
        font-size: var(--font-size-h3);
        text-align: left;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        margin-bottom: 2.5vh;
    }

    .video-width {
        width: 30vw;
        animation-duration: 2s;
        animation-name: slideright;
    }

    .running-image {
        width: 30vw;
    }

    .video-caption {
        font-size: var(--font-size-h4);
        font-style: italic;
        color: var(--color-basic-secondary);
    }

    .music-carousel {
        text-align: center;
        width: 30vw;
    }

    .m-leisure-content-h3 {
        font-size: var(--font-size-h3);
        text-align: center;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
    }

    .carousel-content {
        max-width: 40vw;
    }

    .carousel-container {
        max-width: 40vw;
        margin-left: calc(5px + 0.5vw);
        margin-right: calc(5px + 1.5vw);
    }

    .leisure-top-space {
        height: 5vh;
    }
}

@media screen and (max-width: 1200px) and (min-width: 600px) {

    .leisure-content-container {
        width: 75vw;
        margin-right: 8vw;
        margin-top: 2vh;
        font-size: var(--font-size-h0);
        align-items: center;
    }

    .outdoors-subtitle {
        padding-right: calc(5px + 3vw);
        font-size: var(--font-size-h4);
        text-align: left;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-style: italic;
        margin-bottom: 2.5vh;
        text-align: center;
    }

    .content-container {
        display: flex;
        //flex-direction: column;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
        animation-duration: 2s;
        animation-name: slide-right;
    }

    .music-content-container {
        display: flex;
        flex-direction: column;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
        align-items: center;
        max-width: 70vw;
    }

    .music-content-container-reverse {
        display: flex;
        flex-direction: column-reverse;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
        align-items: center;
        max-width: 70vw;
    }

    .content-container-header {
        display: flex;
        flex-direction: column;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h0);
        margin-bottom: 5vh;
        margin-top: 8vh;
    }

    .leisure-content {
        font-size: var(--font-size-h2);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        text-align: center;
        justify-content: center;
        margin-bottom: 2.5vh;
    }

    .viola-leisure-content {
        font-size: var(--font-size-h2);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        padding-bottom: 5vh;
    }

    .tennis-leisure-content {
        font-size: var(--font-size-h2);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        padding-bottom: 5vh;
    }

    .leisure-content-h3 {
        font-size: var(--font-size-h3);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        text-align: center;
        margin-bottom: 2.5vh;
    }

    .video-width {
        width: 60vw;
        animation-duration: 2s;
        animation-name: slideright;
    }

    .running-image {
        width: 60vw;
        margin-bottom: 2vh;
    }

    .video-caption {
        font-size: var(--font-size-h4);
        font-style: italic;
        color: var(--color-basic-secondary);

    }

    .vid {
        margin-bottom: 4vh;
        align-items: center;
        text-align: center;
    }

    .music-carousel {
        text-align: center;
        width: 60vw;
    }

    .m-leisure-content-h3 {
        font-size: var(--font-size-h3);
        text-align: center;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
    }

    .carousel-content {
        width: 60vw;
    }

    .carousel-container {
        max-width: 60vw;
        margin-left: calc(5px + 0.5vw);
        margin-right: calc(5px + 1.5vw);
    }

    .leisure-top-space {
        height: 0vh;
    }
}

@media screen and (max-width: 600px) {

    .leisure-content-container {
        max-width: 100vw;
        padding-right: 5vw;
        padding-left: 5vw;
        margin-top: 2vh;
        font-size: var(--font-size-h0);
        align-items: center;
        text-align: center;
    }

    .outdoors-subtitle {
        padding-right: calc(5px + 3vw);
        font-size: var(--font-size-h4);
        text-align: left;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-style: italic;
        margin-bottom: 2.5vh;
        text-align: center;
    }

    .content-container {
        display: flex;
        //flex-direction: column;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
        animation-duration: 2s;
        animation-name: slide-right;
    }

    .music-content-container {
        display: flex;
        flex-direction: column;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
        align-items: center;
        max-width: 80vw;
    }

    .music-content-container-reverse {
        display: flex;
        flex-direction: column-reverse;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h2);
        margin-bottom: 5vh;
        align-items: center;
        max-width: 80vw;
    }

    .content-container-header {
        display: flex;
        flex-direction: column;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        font-size: var(--font-size-h1);
        margin-bottom: 5vh;
        margin-top: 5vh;
        text-align: center;
    }

    .leisure-content {
        font-size: var(--font-size-h2);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        text-align: center;
        margin-bottom: 2.5vh;
    }

    .viola-leisure-content {
        font-size: var(--font-size-h3);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        padding-bottom: 5vh;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .tennis-leisure-content {
        font-size: var(--font-size-h3);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        padding-bottom: 5vh;
        width: 80vw;
        text-align: center;
    }

    .leisure-content-h3 {
        font-size: var(--font-size-h3);
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        text-align: center;
        margin-bottom: 2.5vh;
    }

    .video-width {
        width: 60vw;
        animation-duration: 2s;
        animation-name: slideright;
        align-items: center;
    }

    .running-image {
        width: 60vw;
        margin-bottom: 2vh;
    }

    .video-caption {
        font-size: var(--font-size-h4);
        font-style: italic;
        color: var(--color-basic-secondary);

    }

    .vid {
        margin-bottom: 4vh;
        align-items: center;
        text-align: center;
    }

    .music-carousel {
        text-align: center;
        width: 60vw;
    }

    .m-leisure-content-h3 {
        font-size: var(--font-size-body);
        text-align: center;
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
    }

    .carousel-content {
        max-width: 60vw;
    }

    .carousel-container {
        max-width: 60vw;
        margin-left: calc(5px + 0.5vw);
        margin-right: calc(5px + 1.5vw);
    }

    .leisure-top-space {
        height: 0vh;
    }
}

@media screen and (min-width: 1000px) {
    .gallery-image {
        width: 20vw;
        margin: 1vw;
    }
}


@media screen and (max-width: 1000px) and (min-width: 600px) {
    .gallery-image {
        width: 70vw;
        margin: 2vw;
    }
}

@media screen and (max-width: 600px) {
    .gallery-image {
        width: 80vw;
        margin: 2vw;
    }
}

.italic {
    font-style: italic;
}


.linked-content {
    color: var(--color-basic-secondary);
}

.content-columns {
    display: flex;
    flex-direction: column;
    min-width: 30vw;
}

.reverse-columns {
    display: flex;
    flex-direction: column-reverse;
}


.gallery {
    display: flex;
    flex-flow: row wrap;
}

@keyframes slide-left {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}

@keyframes slide-right {
    0% {
      transform: translateX(0%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}


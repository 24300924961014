@import "./../../design-tokens/css-variables.scss";
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);

html {
  scroll-behavior: smooth;
}

.code-font {
  font-family: var(--font-code);
}

.home-link {
  font-family: 'Nunito';
  color: var(--color-secondary);
}

@media screen and (min-width: 1000px) {
  .home-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 4vw;
    padding-right: 4vw;
    min-height: 90vh;
    max-width: 100vw;
    background-color: var(--color-primary);
  }

  .home-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 4vw;
    padding-right: 4vw;
    max-width: 100vw;
    background-color: var(--color-primary);
  }

  .home-flex-projects {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 4vw;
    padding-right: 4vw;
    max-width: 100vw;
    background-color: var(--color-primary);
  }

  .introduction {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h1);
    padding-left: 2vw;
    width: 40vw;
  }

  .introduction-r {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h2);
    padding-right: 4vw;
    width: 35vw;
    text-align: right;
  }

  .introduction-l {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h2);
    padding-right: 4vw;
    width: 35vw;
    text-align: left;
  }

  .home-image {
    width: 50vw;
  }

  .ctc-image {
    width: 50vw;
    margin-right: 4vw;
  }

}

@media screen and (max-width: 1000px) and (min-width: 500px) {
  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary);
  }

  .home-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary);
  }

  .home-flex-projects {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background-color: var(--color-primary);
  }

  .introduction {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h1);
    padding-top: 5vh;
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .introduction-r {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h2);
    padding-top: 5vh;
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .introduction-l {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h2);
    padding-top: 5vh;
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .home-image {
    margin-top: 5vh;
    width: 80vw;
  }

  .ctc-image {
    margin-top: 5vh;
    width: 80vw;
  }
}

@media screen and (max-width: 500px) {
  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary);
  }

  .home-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-primary);
  }

  .home-flex-projects {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background-color: var(--color-primary);
  }

  .introduction {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h2);
    padding-top: 5vh;
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .introduction-r {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h3);
    padding-top: 5vh;
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .introduction-l {
    font-family: 'Nunito';
    color: var(--color-secondary);
    font-size: var(--font-size-h3);
    padding-top: 5vh;
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .home-image {
    margin-top: 5vh;
    width: 80vw;
  }

  .ctc-image {
    margin-top: 5vh;
    width: 80vw;
  }
}

@media screen and (min-width: 500px) {

  .home-container {
    background-color: var(--color-primary);
  }

  .header-home {
    font-size: var(--font-size-h0);
    color: var(--color-secondary);
    margin-bottom: 4vh;
  }

  .intro-interjection {
    font-style: italic;
    font-size: var(--font-size-h2);
  }

  .icon {
    width: calc(16px + 1vw);
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }

  .mail-icon {
    width: calc(16px + 1vw);
    margin-top: calc(2px + 0.1vw);
    margin-left: 1.5vw;
    margin-right: 3vw;
  }

  .parallax-container {
    width: 50vw;
    display: flex;
  }

  .parallax-inner {
    height: 100vh;
    width: 50vw;
  }

  .home-bottom {
    height: 15vh;
    background-color: var(--color-primary);
  }
}

@media screen and (max-width: 500px) {

  .home-container {
    background-color: var(--color-primary);
  }

  .header-home {
    font-size: var(--font-size-h1);
    color: var(--color-secondary);
    margin-bottom: 4vh;
  }

  .intro-interjection {
    font-style: italic;
    font-size: var(--font-size-h3);
  }

  .icon {
    width: calc(16px + 1vw);
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }

  .mail-icon {
    width: calc(16px + 1vw);
    margin-top: calc(2px + 0.1vw);
    margin-left: 1.5vw;
    margin-right: 3vw;
  }

  .parallax-container {
    width: 50vw;
    display: flex;
  }

  .parallax-inner {
    height: 100vh;
    width: 50vw;
  }

  .home-bottom {
    height: 15vh;
    background-color: var(--color-primary);
  }
}



.projects-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--color-basic-primary);
}

.demo-link {
    color: var(--color-basic-primary);
}

@media screen and (min-width: 1000px) {
    .h-project {
        display: flex;
        flex-direction: row;
        background: var(--color-basic-secondary);
        justify-content: space-between;
        width: 100vw;
        height: 92vh;
        align-items: center;
    }

    .etu {
        width:90vh;
        height:100vh;
    }

    .h-description {
        width: 40vw;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .h-project-notice {
        color: var(--color-basic-primary);
        font-family: 'Nunito';
        margin-left: 8vw;
        margin-bottom: 6vh;
        font-size: var(--font-size-h4);
    }

    .h-project-info {
        color: var(--color-basic-primary);
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 20vh;
        margin-top: 20vh;
    }

    .hl-description {
        width: 45vw;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .r-description {
        width: 40vw;
        display: flex;
        text-align: right;
        flex-direction: column;
        justify-content: space-between;
    }

    .l-description {
        width: 50vw;
        display: flex;
        text-align: left;
        flex-direction: column;
        justify-content: space-between;
    }

    .project {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 8vh;
        margin-left: 4vw;
        margin-right: 4vw;
    }

    .l-project-image {
        width: 50vw;
        animation: slidein;
        animation-duration: 2s;
    }

    .r-project-image {
        width: 50vw;
    }

    .parallax-container {
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .other-projects {
        background-color: var(--color-basic-primary);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100vw;
    }

    .project-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 20vh;
        margin-right: 2vw;
        margin-top: 12vh;
    }

    .r-project-descrip {
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        margin-left: 7vw;
        margin-top: 3vh;
        font-size: var(--font-size-h4);
    }

    .r-project-details {
        color: var(--color-basic-secondary);
        margin-left: 7vw;
        font-family: 'Nunito';
        font-size: var(--font-size-body);
    }

    .r-project-details {
        color: var(--color-basic-secondary);
        margin-left: 7vw;
        font-family: 'Nunito';
        font-size: var(--font-size-body);
    }

    .parallax-container {
        width: 65vw;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 5vw;
    }

    .firefly-image {
        max-width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .h-project {
        display: flex;
        flex-direction: column;
        background: var(--color-basic-secondary);
        justify-content: space-between;
        width: 100vw;
    }

    .etu {
        padding-top: 10vh;
        width: 100vw;
        height: 60vh;
    }

    .h-description {
        width: 80vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .h-project-notice {
        color: var(--color-basic-primary);
        font-family: 'Nunito';
        margin-left: 8vw;
        margin-top: 6vh;
        margin-bottom: 6vh;
        font-size: var(--font-size-h4);
    }

    .h-project-info {
        color: var(--color-basic-primary);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 6vh;
    }

    .hl-description {
        width: 80vw;
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .r-description {
        width: 80vw;
        display: flex;
        text-align: right;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 6vh;
    }

    .l-description {
        width: 80vw;
        display: flex;
        text-align: left;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10vh;
        margin-top: -4vh;
    }

    .project {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 4vh;
        margin-left: 4vw;
        margin-right: 4vw;
    }

    .r-project-image {
        width: 80vw;
    }

    .l-project-image {
        width: 80vw;
    }

    .parallax-container {
        width: 90vw;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 5vw;
    }

    .firefly-image {
        max-width: 100%;
    }

    .other-projects {
        background-color: var(--color-basic-primary);
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    .project-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 2vw;
        margin-top: 12vh;
    }

    .r-project-descrip {
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        width: 80vw;
        margin-top: 3vh;
        font-size: var(--font-size-h4);
    }

    .r-project-details {
        color: var(--color-basic-secondary);
        margin-left: 7vw;
        font-family: 'Nunito';
        font-size: var(--font-size-body);
    }
}

@media screen and (max-width: 500px) {
    .r-project-details {
        color: var(--color-basic-secondary);
        margin-left: 7vw;
        font-family: 'Nunito';
        font-size: calc(var(--font-size-body));
    }
}

@keyframes slidein {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

.e-parallax-container {
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/////////HEADER////////////////

.h-project-title {
    color: var(--color);
    font-family: 'Nunito';
    font-weight: bold;
    margin-left: 8vw;
    margin-top: 4vh;
    margin-bottom: 2vh;
    font-size: var(--font-size-h0);
}

.h-project-descrip {
    color: var(--color-basic-primary);
    font-family: 'Nunito';
    font-weight: bold;
    margin-left: 8vw;
    margin-right: 2vw;
    margin-top: 3vh;
    font-size: var(--font-size-h3);
}

.h-project-details {
    color: var(--color-basic-primary);
    font-family: 'Nunito';
    margin-left: 8vw;
    font-size: var(--font-size-h4);
}

/////////////////////////

/////////////////////////
.hl-project-title {
    color: var(--color);
    font-family: 'Nunito';
    font-weight: bold;
    margin-right: 8vw;
    margin-top: 4vh;
    margin-bottom: 2vh;
    font-size: var(--font-size-h0);
    text-align: right;
}

.hl-project-info {
    color: var(--color-basic-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    margin-top: 20vh;
    text-align: right;
}

.hl-project-descrip {
    color: var(--color-basic-primary);
    font-family: 'Nunito';
    font-weight: bold;
    margin-right: 8vw;
    margin-left: 2vw;
    margin-top: 3vh;
    font-size: var(--font-size-h3);
    text-align: right;
}

.hl-project-details {
    color: var(--color-basic-primary);
    font-family: 'Nunito';
    margin-right: 8vw;
    font-size: var(--font-size-h4);
    text-align: right;
}

.hl-project-notice {
    color: var(--color-basic-primary);
    font-family: 'Nunito';
    margin-top: 2vh;
    margin-right: 12vw;
    margin-bottom: 6vh;
    font-size: var(--font-size-h4);
    text-align: right;
}
/////////////////////////


/////////RIGHT////////////////

.r-project-title {
    color: var(--color-secondary);
    font-family: 'Nunito';
    font-weight: bold;
    margin-top: 4vh;
    margin-left: 7vw;
    margin-bottom: 2vh;
    font-size: var(--font-size-h1);
}

//////////////////////////

/////////LEFT////////////////

.l-project-title {
    color: var(--color-secondary);
    font-family: 'Nunito';
    font-weight: bold;
    margin-top: 4vh;
    margin-right: 7vw;
    margin-bottom: 2vh;
    font-size: var(--font-size-h1);
}

.l-project-descrip {
    color: var(--color-basic-secondary);
    font-family: 'Nunito';
    margin-right: 7vw;
    margin-top: 3vh;
    font-size: var(--font-size-h4);
}

.l-project-details {
    color: var(--color-basic-secondary);
    margin-right: 7vw;
    font-family: 'Nunito';
    font-size: var(--font-size-body);
}

//////////////////////////

.bottom {
    height: 15vh;
    background-color: var(--color-basic-primary);
}


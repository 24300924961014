@import url('https://fonts.googleapis.com/css?family=Nunito');

@media screen and (min-width: 600px) {
    .sidebar-container {
        display: flex;
        width: 25vw;
        flex-direction: column;
        margin-top: 14vh;
        animation-duration: 2s;
        animation-name: slideright;
        align-items: left;
    }

    .leisure-subject {
        margin-left: 3vw;
        margin-right: 4vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        align-items: left;
    }
}

@media screen and (max-width: 600px) {
    .sidebar-container {
        display: flex;
        width: 100vw;
        flex-direction: row;
        margin-top: 4vh;
        animation-duration: 2s;
        animation-name: slideright;
        justify-content: center;
        align-items: center;
    }

    .leisure-subject {
        margin-left: 2vw;
        margin-right: 2vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        align-items: center;
    }
}



.featured-leisure-button {
    text-decoration: underline;
    border: none;
    background: none;
    font-family: 'Nunito';
    font-size: var(--font-size-h3);
    color: var(--color-basic-secondary);
    text-align: left;
}

.leisure-button {
    border: none;
    background: none;
    cursor: pointer;
    font-family: 'Nunito';
    font-size: var(--font-size-h3);
    color: var(--color-basic-secondary);
    align-items: left;
    text-align: left;
}

.leisure-button:hover {
    color: var(--color);
    font-family: 'Nunito';
    font-size: var(--font-size-h3);
}

@keyframes slideright {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}
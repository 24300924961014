@import url('https://fonts.googleapis.com/css?family=Nunito');


.big-footer-container {
    background-color: var(--color-primary);
    max-width: 100vw;
}

.creation-note {
    color: var(--color-secondary);
    font-size: var(--font-size-body);
    padding-right: 6vw;
    padding-left: 8vw;
}


@media screen and (min-width: 800px) {
    .nav-container {
        height: calc(8vh + 20px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5%;
        padding-top: calc(1vh + 1px);
        padding-left: 4vw;
        padding-right: 4vw;
        background-color: var(--color-primary);
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
        text-align: center;
        background-color: var(--color-primary);
        font-family: 'Nunito';
    }

    .inner-footer-container {
        display: flex;
        justify-content: flex-start;
        text-align: center;
        background-color: var(--color-primary);

    }
}

@media screen and (max-width: 800px) {
    .nav-container {
        height: 8vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5%;
        padding-top: 1%;
        padding-left: 4vw;
        padding-right: 4vw;
        background-color: var(--color-primary);
    }

    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--color-primary);
    }

    .inner-footer-container {
        display: flex;
        justify-content: flex-start;
        text-align: center;
        background-color: var(--color-primary);

    }
}

// @media screen and (max-width: 500px) {
//     .nav-container {
//         height: 8vh;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding: 0.5%;
//         padding-top: 1%;
//         padding-left: 4vw;
//         padding-right: 4vw;
//         background-color: var(--color-primary);
//         max-width: 100vw;
//     }

//     .footer-container {
//         display: flex;
//         justify-content: space-around;
//         background-color: var(--color-primary);
//         padding-top: 4vh;
//     }
//     .inner-footer-container {
//         display: flex;
//         justify-content: center;
//         text-align: center;
//         background-color: var(--color-primary);
//     }

//     .creation-note {
//         color: var(--color-secondary);
//         font-size: var(--font-size-body);
//         text-align: center;
//     }

// }

@media screen and (min-width: 500px) {

    .home {
        display: flex;
        justify-content: space-around;
    }

    .signature {
        font-family: 'Nunito';
        text-decoration: none;
        color: var(--color-secondary);
        font-weight: bold;
        font-size: var(--font-size-h3);
    }

    .signature:hover {
        font-family: 'Nunito';
        text-decoration: none;
        color: var(--color-highlight);
        font-weight: bold;
        font-size: var(--font-size-h3);
    }

    .links {
        display: flex;
        justify-content: space-around;
    }

    .link {
        font-family: 'Nunito';
        color: var(--color-secondary);
        text-decoration: none;
        font-size: var(--font-size-h3);
        margin-left: 1.8vw;
        margin-right: 1.8vw;

    }

    .flinks {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 8vw;
        padding-right: 2vw;
    }

    .flink {
        font-family: 'Nunito';
        color: var(--color-secondary);
        text-decoration: none;
        font-size: var(--font-size-h2);
    }

    .flink-style {
        cursor: pointer;
        text-decoration: underline;
        color: var(--color-secondary);
    }

    .flink-text {
        font-family: 'Nunito';
        color: var(--color-secondary);
        text-decoration: none;
        font-size: var(--font-size-h3);
        padding: calc(0.25vw + 0.25vh);
        padding-left: 1.5vw;
        cursor: pointer;
        text-decoration: underline;
    }

    .flink-text-special {
        font-family: 'Nunito';
        color: var(--color-secondary);
        text-decoration: none;
        font-size: var(--font-size-h3);
        padding: calc(0.25vw + 0.25vh);
        padding-left: 1.5vw;
    }

    .link:hover {
        font-family: 'Nunito';
        color: var(--color-highlight);
        text-decoration: none;
        font-size: var(--font-size-h3);
        margin-left: 1.8vw;
        margin-right: 1.8vw;

    }
}

@media screen and (max-width: 500px) {

    .home {
        display: flex;
        justify-content: space-around;
    }

    .signature {
        font-family: 'Nunito';
        text-decoration: none;
        color: var(--color-secondary);
        font-weight: bold;
        font-size: calc(12px + 0.2vw);
    }

    .signature:hover {
        font-family: 'Nunito';
        text-decoration: none;
        color: var(--color-highlight);
        font-weight: bold;
        font-size: calc(12px + 0.2vw);
    }

    .links {
        display: flex;
        justify-content: space-around;
    }

    .link {
        font-family: 'Nunito';
        color: var(--color-secondary);
        text-decoration: none;
        font-size: calc(12px + 0.2vw);
        margin-left: 1.8vw;
        margin-right: 1.8vw;

    }

    .flinks {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: 2vw;
        padding-right: 2vw;
    }

    .flink {
        font-family: 'Nunito';
        color: var(--color-secondary);
        text-decoration: none;
        font-size: calc(14px + 0.2vw);
    }

    .flink-style {
        cursor: pointer;
        text-decoration: underline;
        color: var(--color-secondary);
    }

    .flink-text {
        font-family: 'Nunito';
        color: var(--color-secondary);
        text-decoration: none;
        font-size: calc(12px + 0.2vw);;
        padding: calc(0.25vw + 0.25vh);
        padding-left: 1.5vw;
        cursor: pointer;
        text-decoration: underline;
    }

    .flink-text-special {
        font-family: 'Nunito';
        color: var(--color-secondary);
        text-decoration: none;
        font-size: calc(12px + 0.2vw);
        padding: calc(0.25vw + 0.25vh);
        padding-left: 1.5vw;
    }

    .link:hover {
        font-family: 'Nunito';
        color: var(--color-highlight);
        text-decoration: none;
        font-size: calc(12px + 0.2vw);
        margin-left: 1.8vw;
        margin-right: 1.8vw;
    }
}
.experience-container {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    background-color: var(--color-basic-primary);
}

.experiences {
    background-color: var(--color-basic-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
}

.experience-header {
    color: var(--color-basic-secondary);
    font-size: calc(var(--font-size-h0) + 10px);
    font-family: 'Nunito';
    font-weight: bold;
    margin: 2vh;
}

.exp-other-projects {
    background-color: var(--color-primary);
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-top: 4vh;
    align-items: center;
}

.l-project-details-exp {
    color: var(--color-basic-secondary);
    margin-right: 7vw;
    font-family: 'Nunito';
    font-size: calc(var(--font-size-body));
}

@media screen and (min-width: 800px) {
    .exp-image {
        width: 40vw;
        animation: slidein;
        animation-duration: 2s;
    }

    .exp-project {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 8vh;
        margin-left: 4vw;
        margin-right: 4vw;
    }

    .exp-project-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 20vh;
        margin-right: 2vw;
        margin-top: 12vh;
    }

    .l-project-descrip-exp {
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        margin-right: 7vw;
        margin-top: 3vh;
        font-size: var(--font-size-h4);
    }
}

@media screen and (max-width: 800px) {
    .exp-image {
        margin: 2vh;
        margin-top: 6vh;
        width: 80vw;
    }

    .exp-project {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 4vw;
        margin-right: 4vw;
    }

    .exp-project-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80vw;
        margin-top: 3vh;
    }

    .l-project-details-exp {
        color: var(--color-basic-secondary);
        margin-right: 7vw;
        margin-bottom: 2vh;
        font-family: 'Nunito';
        font-size: calc(var(--font-size-body));
    }

    .l-project-descrip-exp {
        color: var(--color-basic-secondary);
        font-family: 'Nunito';
        width: 80vw;
        margin-bottom: 2vh;
        font-size: var(--font-size-h4);
    }
}

@media screen and (max-width: 500px) {
    .l-project-details-exp {
        color: var(--color-basic-secondary);
        margin-right: 7vw;
        margin-bottom: 2vh;
        font-family: 'Nunito';
        font-size: calc(var(--font-size-body));
    }
}

@keyframes slidein {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

.leisure-container {
    display: flex;
    flex-direction: column;
    background-color: var(--color-basic-primary);
    min-height: 100vh;
}

@media screen and (min-width: 600px) {
    .leisure-content {
        display: flex;
        flex-direction: row;
        max-width: 100vw;
        background-color: var(--color-basic-primary);
    }
}

@media screen and (max-width: 600px) {
    .leisure-content {
        display: flex;
        flex-direction: column;
        max-width: 100vw;
        background-color: var(--color-basic-primary);
    }
}
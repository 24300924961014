:root {
    --color: #202A44;
    --color-primary: #ffffff;
    --color-secondary: #202A44;
    --color-highlight: #ffffff;

    --color-basic-primary: #ffffff;
    --color-basic-secondary: #202A44;

    --font-size-h0: calc(42px + 0.2vw);
    --font-size-h1: calc(24px + 0.2vw);
    --font-size-h2: calc(18px + 0.2vw);
    --font-size-h3: calc(14px + 0.2vw);
    --font-size-h4: calc(12px + 0.2vw);
    --font-size-body: calc(10px + 0.2vw);

    --font-regular: 'Nunito';
    --font-code: 'Ubuntu Mono';
}

